import "../resources/css/main.scss";

const demoSrc = "https://www.youtube-nocookie.com/embed/zc53Oe06aTM?rel=0&autoplay=0"; //https://youtu.be/zc53Oe06aTM  | https://www.youtube-nocookie.com/embed/zc53Oe06aTM?rel=0&autoplay=0
let isFormSent = false;

document.addEventListener( 'wpcf7mailsent', function( event ) {
  const demoLink = document.querySelector('#demo-link');
  const demoSentForm = document.querySelector('#send-demo-form'); // input t:submit
  const demoView = document.querySelector('#view-demo'); // a, link pos envio

  demoLink.setAttribute( 'src', demoSrc );
  isFormSent = true;
  demoSentForm.classList.add( 'd-none' );
  demoView.classList.remove( 'd-none' );

//   gtag( 'event', 'conversion', {
//     'send_to': 'AW-10805606933/6pzBCJ2V6dcZEJX0waAo',
//     'event_category': event.detail.contactFormId,
//     'event_label': event.detail.unitTag
// } );
}, false );
